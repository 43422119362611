var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CEmbed", {
    staticClass: "w-100 embedded-content-lg",
    attrs: {
      src:
        "https://demo1.aarcomm.io/api/grafana/d/8sJNq-AGz/route-admin-dash?var-minLat=49.107140802916284&var-maxLat=49.338546125046875&var-minLon=-123.34075927734376&var-maxLon=-122.32864379882814&orgId=1&from=1600585201500&to=1602658797500&kiosk=tv",
      ratio: "1by1"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }